<!-- Vocabulary Word Separator -->
<ng-template #vocabulary_word_separator_template let-char="char">
  <div class="followMeBar">
    <span class="alpha">{{char}}</span>
  </div>
</ng-template>

<!-- Vocabulary Word -->
<ng-template #vocabulary_word_template let-vocabularyWord="vocabularyWord">
  <dl>
    <dt>{{vocabularyWord.key}}</dt>
    <dd>{{vocabularyWord.value}}</dd>
  </dl>
</ng-template>

<!-- Vocabulary Group -->
<ng-template #vocabulary_group_template let-vocabularyGroup="vocabularyGroup" let-vocabularyWords="vocabularyWords" let-showCurrentChar="showCurrentChar">
  <ng-container *ngIf="vocabularyGroup && vocabularyWords">
    <div style="padding-left: 0" *ngIf="vocabularyWords.length > 0">
      <div class="followMeBar" *ngIf="vocabularyGroup.name">
        <span class="alpha" translate="{{ vocabularyGroup.name.length > 1 ? vocabularyGroup.name : vocabularyGroup.name.toUpperCase() }}"></span>
      </div>
      <ng-container *ngFor="let vocabularyWord of vocabularyWords">
        <ng-container *ngIf="showCurrentChar && getCurrentChar(vocabularyWord) as char">
          <ng-container *ngTemplateOutlet="vocabulary_word_separator_template;context:{
                        char:char}"></ng-container>
        </ng-container>
        <ng-container *ngTemplateOutlet="vocabulary_word_template;context:{
                      vocabularyWord: vocabularyWord}"></ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<!-- Content -->
<div class="vocabulary mdsf-text scrollable editor-input">
  <section>
    <h1 translate="vocabulary_anatomy"></h1>
    <p [innerHTML]="vocabulary.description"></p>
    <div class="vocabulary-filter">
      <input class="vocabulary-filter-input"
             (keyup)="setFilter(vocabularyFilter.value)"
             placeholder="{{ 'search_after' | translate }}" #vocabularyFilter/>
    </div>
    <div>
      <input class="vocabulary-filter-placeholder"/>
    </div>
    <br>
    <ng-container *ngIf="(vocabularyViewerFacade.vocabularyGroupByIds$(vocabulary.vocabularyGroups) | async | sortBy_shared:'asc':'order') as vocabularyGroups">
      <ng-container *ngFor="let vocabularyGroup of vocabularyGroups">
        <ng-container *ngIf="(vocabularyViewerFacade.vocabularyWordByIds$(vocabularyGroup.vocabularyWords) | async | sortBy_shared:'asc':'key':true) as vocabularyWords">
          <ng-container *ngTemplateOutlet="vocabulary_group_template;context:{
                        vocabularyGroup: vocabularyGroup,
                        vocabularyWords: filterVocabularyWords(vocabularyWords),
                        showCurrentChar: vocabularyWords.length > 100}"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </section>
</div>
